import getNewsboardFieldVariables
  from './queries/getNewsboardFieldVariables';
import { featuredCategoryIds } from './mainCategoryIds';
import featured from
  './queries/news/variables/featured';

const getFeaturedVariables = (
  prefix: string,
  hasLocalNews: boolean,
  newsboard: string,
  notInIds: number[],
  variables: object,
) => ({
  ...getNewsboardFieldVariables(prefix, hasLocalNews),
  withoutSummaryAndLabel: false,
  withoutDescriptionAndCreator: false,
  ...featured,
  newsboards: [newsboard],
  notInIds,
  categoryIds: featuredCategoryIds,
  ...variables,
});

export default getFeaturedVariables;
