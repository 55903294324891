import React from 'react';
import PropTypes from 'prop-types';
import TopNewsSection from './Featured/TopNewsSection';

const ArticleLayout = ({
  featuredVariables,
  moreNewsVariables,
  categorySection,
  showCreatedAt,
  expandedSections,
}) => (
  <TopNewsSection
    variables={featuredVariables}
    showCreatedAt={showCreatedAt}
    expandedSections={expandedSections}
    moreNewsVariables={moreNewsVariables}
    categorySection={categorySection}
  />
);

ArticleLayout.propTypes = {
  /** Add or overwrite featured variables */
  featuredVariables: PropTypes.shape({
    categoryIds: PropTypes.arrayOf(PropTypes.number),
  }),
  /** Add or overwrite more news variables */
  moreNewsVariables: PropTypes.shape({
    categoryIds: PropTypes.arrayOf(PropTypes.number),
  }),
  categorySection: PropTypes.node,
  /** Show or hide date posted. */
  showCreatedAt: PropTypes.bool,
  /** Set moreNews for "Featured" and "More News" block */
  expandedSections: PropTypes.bool,
};

ArticleLayout.defaultProps = {
  featuredVariables: null,
  moreNewsVariables: null,
  categorySection: null,
  showCreatedAt: false,
  expandedSections: false,
};

export default ArticleLayout;
