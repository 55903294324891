import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import prune from 'underscore.string/prune';
import { useSiteContext } from './SiteContext';
import onRouteChangeFinished from '../lib/onRouteChangeFinished';
import Pageview from './Pageview';
import PlausibleProperties from './PlausibleProperties';
import { AccessLevel } from '../lib/graphql/api/graphql';

const maxMetaDescriptionLength = 160;
const truncateString = '...';
const pruneLength = maxMetaDescriptionLength - truncateString.length;

const getCanonicalUrl = (url: string) => {
  const parsedUrl = new URL(url);
  parsedUrl.search = '';

  return `${parsedUrl}`;
};

const headPropertyTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  insertedAt: PropTypes.string,
  author: PropTypes.string,
  robotsMeta: PropTypes.string,
  jsonLdData: PropTypes.string,
  addCanonical: PropTypes.bool,
  children: PropTypes.node,
  prelude: PropTypes.node,
  subjectType: PropTypes.string,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payload: PropTypes.shape({}),
  id: PropTypes.number,
  featured: PropTypes.bool,
  pressRelease: PropTypes.bool,
  partnerContent: PropTypes.bool,
  category: PropTypes.shape({}),
  accessLevel: PropTypes.oneOf(Object.values(AccessLevel)),
};

type HeadProperties = Omit<InferProps<
  typeof headPropertyTypes
>, 'accessLevel'> & {
  accessLevel?: AccessLevel | null | undefined;
};

const Head = ({
  title,
  description,
  imageUrl: originalImageUrl,
  imageDimensions,
  insertedAt,
  author,
  robotsMeta,
  jsonLdData,
  addCanonical = true,
  children,
  prelude,
  subjectType,
  subjectId,
  payload,
  id,
  featured,
  pressRelease,
  partnerContent,
  category,
  accessLevel,
} : HeadProperties) => {
  const { origin } = useSiteContext();
  const router = useRouter();

  const url = `${new URL(router.asPath, origin)}`;

  useEffect(() => {
    if (title) {
      onRouteChangeFinished();
    }
  }, [url, title]);

  const truncatedDescription = prune(description, pruneLength);
  const canonicalUrl = getCanonicalUrl(url);

  // WORKAROUND for linkedin bug
  const imageUrl = originalImageUrl?.replace(/\/r\//, '/cdn-cgi/image/');

  return (
    <>
      <NextHead>
        <title key="title">{title}</title>
        {prelude}
        {robotsMeta && <meta name="robots" content={robotsMeta} />}
        <meta property="og:site_name" content="FashionUnited" />
        <meta name="twitter:site" content="@FashionUnited" />
        <meta
          name="og:title"
          key="og:title"
          property="og:title"
          content={title}
        />
        <meta name="twitter:title" key="twitter:title" content={title} />
        <meta name="description" content={truncatedDescription} />
        <meta property="og:description" content={truncatedDescription} />
        <meta name="twitter:description" content={truncatedDescription} />
        {author
        && (
          <>
            <meta property="og:type" content="article" />
            <meta property="article:author" content={author} />
            <meta name="twitter:creator" content={author} />
          </>
        )}
        {url && <meta property="og:url" content={url} />}
        {insertedAt && <meta property="DC.date.issued" content={insertedAt} />}
        {imageUrl
        && (
          <>
            <meta property="og:image" content={imageUrl} />
            <meta
              property="og:image:secure_url"
              content={imageUrl}
            />
            {imageDimensions?.width && (
              <meta
                property="og:image:width"
                content={`${imageDimensions.width}`}
              />
            )}
            {imageDimensions?.height && (
              <meta
                property="og:image:height"
                content={`${imageDimensions.height}`}
              />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:image:src" content={imageUrl} />
          </>
        )}
        {addCanonical && <link rel="canonical" href={canonicalUrl} />}
        {jsonLdData
        && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: jsonLdData,
            }}
          />
        )}
        {children}
      </NextHead>
      <Pageview
        title={title}
        subjectType={subjectType}
        subjectId={subjectId}
        payload={{
          ...payload,
          ...(author ? { author } : {}),
        }}
      />
      <PlausibleProperties
        author={author}
        id={id}
        insertedAt={insertedAt}
        featured={featured}
        pressRelease={pressRelease}
        partnerContent={partnerContent}
        category={category}
        accessLevel={accessLevel}
      />
    </>
  );
};

Head.propTypes = headPropertyTypes;

export default Head;
