import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

const errorPropertyTypes = {
  error: PropTypes.string.isRequired,
};

type ErrorProperties = InferProps<
  typeof errorPropertyTypes
>;

const Error = ({ error }: ErrorProperties) => (
  <div>{error}</div>
);

Error.propTypes = errorPropertyTypes;

export default Error;
