import Router from 'next/router';

const routeChangeReady = () => {
  setTimeout(() => {
    try {
      window.dataLayer.push({ event: 'routeChangeFinished' });
    } catch {
      // ignore when tracking is blocked
    }
  }, 50);
};
let ready = false;
const onRouteChangeFinished = () => {
  if (!ready) {
    ready = true;
    routeChangeReady();
  }
};
export const handleRouteChangeStart = () => {
  ready = false;
  // after 1s call it always
  setTimeout(onRouteChangeFinished, 1000);
};

Router.events.on('routeChangeStart', handleRouteChangeStart);

export default onRouteChangeFinished;
