import { useEffect, useContext } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useRouter } from 'next/router';
import { UserContext } from './UserProvider';
import { useSiteContext } from './SiteContext';
import { AccessLevel } from '../lib/graphql/api/graphql';

const plausiblePropertiesPropertyTypes = {
  author: PropTypes.string,
  id: PropTypes.number,
  insertedAt: PropTypes.string,
  featured: PropTypes.bool,
  pressRelease: PropTypes.bool,
  partnerContent: PropTypes.bool,
  category: PropTypes.shape({
    title: PropTypes.string,
  }),
  accessLevel: PropTypes.string,
};

type PlausiblePropertiesProperties = Omit<InferProps<
  typeof plausiblePropertiesPropertyTypes
>, 'accessLevel'> & {
  accessLevel?: AccessLevel | null | undefined;
};

const accessLevels = new Set(Object.values(AccessLevel));

const PlausibleProperties = ({
  author,
  id,
  insertedAt,
  featured,
  pressRelease,
  partnerContent,
  category,
  accessLevel,
}: PlausiblePropertiesProperties) => {
  const { title: categoryTitle } = category ?? {};
  const { route, query } = useRouter();
  const { user, loading } = useContext(UserContext);
  const { locale } = useSiteContext();

  useEffect(() => {
    if (!loading) {
      const isLoggedIn = user !== null;
      const isVerified = user?.verified;

      window.plausible('pageview', {
        props: {
          author,
          id,
          insertedAt,
          featured,
          pressRelease,
          partnerContent,
          categoryTitle,
          isLoggedIn,
          locale,
          articleType: accessLevel,
          ...(accessLevel && accessLevels.has(accessLevel)) ? {
            paywallTriggered: (
              (!isLoggedIn || !isVerified)
              && accessLevel !== AccessLevel.FREE
            ),
          } : {},
        },
      });
    }
  }, [
    loading,
    user,
    author,
    id,
    insertedAt,
    featured,
    pressRelease,
    partnerContent,
    categoryTitle,
    route,
    query,
    locale,
    accessLevel,
  ]);

  return null;
};

export default PlausibleProperties;
