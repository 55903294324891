import styled from '@emotion/styled';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin: .5rem 0;
`;

export default ErrorWrapper;
