import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes, { InferProps } from 'prop-types';
import { useRouter } from 'next/router';
import createAnalyticsEvent
  from '../lib/mutations/createAnalyticsEvent.graphql';
import { AnalyticsEventType } from '../lib/graphql/api/graphql';

const pageviewPropertyTypes = {
  title: PropTypes.string,
  subjectType: PropTypes.string,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payload: PropTypes.shape({}),
};

type PageviewProperties = InferProps<typeof pageviewPropertyTypes>;

const Pageview = ({
  title,
  subjectType,
  subjectId,
  payload,
} : PageviewProperties) => {
  const [createPageview] = useMutation(createAnalyticsEvent);
  const { route, query } = useRouter();

  useEffect(() => {
    createPageview({
      variables: {
        type: AnalyticsEventType.PAGEVIEW,
        url: window.location.href,
        title,
        subjectType,
        subjectId: subjectId ? `${subjectId}` : null,
        payload: JSON.stringify({ ...payload }),
      },
    });
    // don't re-run it even when data changes
    // only route and query changes should trigger this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ route, query })]);

  return null;
};

Pageview.propTypes = pageviewPropertyTypes;

export default Pageview;
