import { camelCase } from 'lodash';

const defaultNewsboardFieldVariables = {
  centralNewsboard: false,
  auFashionunitedCom: false,
  fashionunitedBeFr: false,
  fashionunitedBe: false,
  fashionunitedCaFr: false,
  fashionunitedCa: false,
  fashionunitedCh: false,
  fashionunitedCl: false,
  fashionunitedCn: false,
  fashionunitedCo: false,
  fashionunitedComAr: false,
  fashionunitedCom: false,
  fashionunitedComPe: false,
  fashionunitedDe: false,
  fashionunitedEs: false,
  fashionunitedFr: false,
  fashionunitedIn: false,
  fashionunitedInfo: false,
  fashionunitedIt: false,
  fashionunitedMx: false,
  fashionunitedNl: false,
  fashionunitedNz: false,
  fashionunitedRu: false,
  fashionunitedUk: false,
  fashionweekwebCom: false,
  internationalfashionjobsCom: false,
};

const getNewsboardFieldVariables = (
  prefix: string,
  hasLocalNews: boolean,
) => ({
  ...defaultNewsboardFieldVariables,
  centralNewsboard: !hasLocalNews,
  ...hasLocalNews ? { [camelCase(prefix)]: true } : {},
});

export default getNewsboardFieldVariables;
